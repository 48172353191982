var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{class:{
				'no-data__table' : (_vm.tableData.length === 0),
				'no-current__pagination' : (_vm.pagination && _vm.pagination.last_page <= 1),
			},attrs:{"fixed-header":"","show-select":"","disable-pagination":"","single-select":false,"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.isBillLoading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.tableData.length > 0)?_c('div',{staticClass:"d-md-flex justify-end w-100 pt-3 px-4 pb-3",staticStyle:{"border-bottom":"1px solid #EBF2F5 !important"}},[_c('div',{staticClass:"d-md-flex justify-md-end my-2 my-md-0 mx-md-0"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"search-field-wrapper"},[_c('Search',{attrs:{"inputData":_vm.searchText,"placeholder":"Search Bill #"},on:{"update:inputData":function($event){_vm.searchText=$event},"update:input-data":function($event){_vm.searchText=$event}}})],1)])])]):_vm._e()]},proxy:true},{key:"item.issued_at",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.issued_at))+" ")]}},{key:"item.due_at",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.due_at))+" ")]}},{key:"item.actions",fn:function(ref){
			var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","content-class":"outbound-lists-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-more-items btn-white",attrs:{"primary":"","text":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(("/accounting/bills/" + (item.id)))}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.onSelectEditBill(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1)],1)],1)]}},{key:"item.status",fn:function(ref){
			var item = ref.item;
return [_c('span',{staticClass:"text-uppercase",class:{
						'status-paid': item.status === 'paid',
						'status-overdue': item.status === 'overdue',
						'status-partial': item.status === 'partial',
						'status-open': item.status === 'open',
						'status-draft': item.status === 'draft',
						'status-cancelled': item.status === 'cancelled',
						'status-sent': item.status === 'sent'
					}},[_vm._v(_vm._s(item.status))])]}},{key:"no-data",fn:function(){return [(_vm.isBillLoading)?_c('div',{staticClass:"loading-wrapper pt-6"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}})],1):_vm._e(),(!_vm.isBillLoading && _vm.tableData.length == 0)?_c('div',{staticClass:"no-data-wrapper pt-6",staticStyle:{"min-height":"200px"}},[_c('h3',{staticClass:"font-20 mb-1"},[_vm._v(" No Bills found. ")]),_c('p',[_vm._v(" There are no bills listed yet. ")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedBills),callback:function ($$v) {_vm.selectedBills=$$v},expression:"selectedBills"}}),_c('pagination',{attrs:{"total":_vm.pagination ? _vm.pagination.last_page : 1,"count":_vm.pagination ? _vm.pagination.total : 0,"current-page":_vm.currentPage,"total-visible":10,"pageLimit":_vm.pageLimit,"from":_vm.pagination ? _vm.pagination.from : 1,"to":_vm.pagination ? _vm.pagination.to : 1},on:{"update:pageLimit":function($event){_vm.pageLimit=$event},"update:page-limit":function($event){_vm.pageLimit=$event},"pageSelected":_vm.onPaginationClick,"changeLimit":_vm.onChangePageLimit}}),_c('billing-form',{attrs:{"open":_vm.showFormDialog,"is-edit-mode":_vm.isEditMode,"form-values":_vm.selectedBillData},on:{"toggle":_vm.onToggleBillForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }